import styled, { css } from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import Tokens from '@veneer/tokens'
import { left } from '../../styles/global'

const flexColumn = css`
  display: flex;
  flex-direction: column;
`
export const Column = styled.div`
  ${flexColumn};
  flex: 1;
  height: 100%;
  ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
`

export const ItemContent = styled.div`
  text-align: ${left};
  ${flexColumn};
  justify-content: flex-end;
  margin-top: ${Tokens.space3};
`

export const ItemTitle = styled.span`
  margin: 0;
  color: ${({ theme }) => theme.colorForeground};
  &.label {
    font-size: ${Tokens.size4};
    line-height: ${Tokens.lineHeight3};
  }
`

export const ItemDescription = styled.span`
  display: inline-block;
  // margin-top: ${Tokens.space2};
  color: ${({ theme }) => theme.colorForegroundLight};
`

export const FlexRow = styled.div`
  display: grid;
  position: relative;
  flex-wrap: wrap;
  gap: ${Tokens.space4};
  grid-template-columns: repeat(auto-fill, minmax(min-content, 170px));
  ${(props) =>
    props.minWidth &&
    props.maxWidth &&
    css`
      grid-template-columns: repeat(
        auto-fill,
        minmax(
          ${(props) => props.minWidth || 165}px,
          ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '1fr')}
        )
      );
    `}
`

export const Col = styled.div`
  box-sizing: border-box;
  flex-basis: ${(props) => (props.size ? 100 / props.size : 100)}%;
  max-width: ${(props) => (props.size ? 100 / props.size : 100)}%;
  ${(props) =>
    props.flex &&
    css`
      display: flex;
      > div {
        flex: 1;
      }
    `}
`

export const IconContainer = styled.div`
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
`
