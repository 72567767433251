import * as React from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import { LocalizationType } from '../types/localization'

interface IModal {
  modals: any
  openModal: (modal) => void
  updateModal: (key, param) => void
  setOpen: (isOpen: boolean) => void
}

interface IContext {
  useModal?: () => IModal
  localization?: LocalizationType
}

const useVAModal = () => {
  const { useModal } = React.useContext<IContext>(RootContext)
  const { openModal, updateModal, modals } = useModal()
  return {
    openModal,
    updateModal,
    modals
  }
}

export default useVAModal
