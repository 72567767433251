import {
  createProcess,
  launchUrlFromCommand
} from '@jarvis/web-support-commons'

const useLauncher = (device: any) => {
  const launcherWinDiag = async () => {
    const winDiagExist = await createProcess(
      '/HPSALauncher /iswindiagexist',
      true,
      true,
      true
    )

    if (winDiagExist === 0) {
      createProcess('/HPSALauncher /launchhphwdiag', true, false, true)
    } else if (winDiagExist === 1) {
      launchUrlFromCommand('"HPSALauncher.exe" learnwindiags', device)
      console.log('windiag not exist') //call launcher to open the downloanding URL with context string "learnwindiags"       }
    }
  }

  const launcherDaigTool = async (command) => {
    createProcess(`${command}`, true, false, true)
  }

  return {
    launcherWinDiag,
    launcherDaigTool
  }
}

export default useLauncher
