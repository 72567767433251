/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import { Column } from './styles'
import { Container, Header, PageTitle, Section, Row } from './../styles'
import GridView from './GridView'
const component = 'diagnostics'
import {
  getDiagnosticsResources,
  IResource
} from '@jarvis/react-hpx-support-shared'

const RootComponent = (props) => {
  const { t } = useI18n()
  const { formatAutoId } = useContext<any>(RootContext)
  const [resources, setResources] = useState<IResource[]>()
  const { device, Skelton } = props
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        if (device) {
          const response = await getDiagnosticsResources(device, t)
          setResources(response)
        }
      } catch (e) {
        console.log(e)
        setResources([])
      } finally {
        setLoading(false)
      }
    })()
  }, [device?.ProductType])

  return (
    <>
      {!loading ? (
        <>
          {resources?.length > 0 && (
            <Section className="m-b-12">
              <Row>
                <Column>
                  <Header>
                    <PageTitle
                      className="subtitle-regular"
                      id={formatAutoId(`${component}-title`)}
                    >
                      {t('common.diagnostics', 'Diagnostics')}
                    </PageTitle>
                  </Header>
                  <Container>
                    <GridView resources={resources} device={device}></GridView>
                  </Container>
                </Column>
              </Row>
            </Section>
          )}
        </>
      ) : (
        <Section className="m-b-12">
          <Skelton />
        </Section>
      )}
    </>
  )
}

export default RootComponent
