/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import {
  ItemContent,
  ItemDescription,
  ItemTitle,
  FlexRow,
  Col,
  IconContainer
} from '../styles'
import { Box } from '../../styles'
import IconDiagnose from '@veneer/core/dist/esm/scripts/icons/icon_diagnose'
import IconPeople from '@veneer/core/dist/esm/scripts/icons/icon_people'
import IconPrinter from '@veneer/core/dist/esm/scripts/icons/icon_printer'
import { va } from '../../../assets/images'
import useLauncher from '../../../hooks/useLauncher'
import useVAModal from '../../../hooks/useVAModal'
import { genTestId } from '../../../utils'
import { SupportGuidedTroubItemComponent } from '../../../utils/constants'

const component = 'diagnostics'

const ListView = ({ resources, isExecuteLauncher, device }) => {
  const { launcherWinDiag } = useLauncher(device)
  const { formatAutoId } = useContext<any>(RootContext)
  const { t } = useI18n()
  const { IsChromebook } = device || {}

  const { openModal, updateModal, modals } = useVAModal()
  const contactMap = {
    // eslint-disable-next-line react/display-name
    diagnostics: (size: number, color: any) => {
      return <IconDiagnose className="icon-color" size={size} color={color} />
    },
    HPAllInPlanSupportPeople: (size: number, color: any) => {
      return <IconPeople className="icon-color" size={size} color={color} />
    },
    printerAndInkSupport: (size: number, color: any) => {
      return <IconPrinter className="icon-color" size={size} color={color} />
    },
    // eslint-disable-next-line react/display-name
    default: () => {
      return <img src={va} />
    }
  }

  const onIsChromeBook = () => {
    if (!IsChromebook) return false
    let bool = true
    const modalArray = Object.keys(modals)
    for (let i = 0; i < modalArray.length; i++) {
      if (modalArray[i] === 'VA') {
        const modal = modals[modalArray[i]]
        if (modal?.isOpen && modal?.sessionStatus && IsChromebook) {
          bool = false
        } else {
          bool = true
        }
        break
      }
    }
    return bool
  }

  const launchVA = (type: string) => {
    let printerVADescContext = ''
    let vaModalTitle = !IsChromebook
      ? t('common.devicesupport')
      : t('common.hpVirtualAssistant')
    if (type === 'HPAllInPlanSupportPeople') {
      vaModalTitle = t('common.hPAllInPlanSupport')
      printerVADescContext = t(
        'common.ourVirtualAssistantCanHelpYouCommonIssues'
      )
    } else if (type === 'PrinterAndInkSupport') {
      vaModalTitle = t('common.printerAndInkSupport')
      printerVADescContext = t(
        'common.ourVirtualAssistantCanHelpYouPrintIssues'
      )
    }
    const VA = {
      isOpen: true,
      category: {
        type: type,
        contextTitle: vaModalTitle
      },
      config: {
        printerVADescContext: printerVADescContext,
        [type]: { isOpen: false },
        ...modals?.VA?.config
      },
      width: '75%',
      hide: onIsChromeBook(),
      resize: false,
      requireAuthLogin: false,
      props: { device }
    }
    if (modals?.VA) {
      updateModal('VA', VA)
    } else {
      openModal({ VA })
    }
  }

  return (
    <FlexRow>
      {resources &&
        resources.map((resource) => (
          <Col size="1" flex="1" key={resource.serviceType}>
            <Box
              role="button"
              className="cardStyle"
              id={formatAutoId(`${component}-card-${resource.serviceId}`)}
              trackId={genTestId(
                'Guid',
                `${resource?.serviceId?.toLowerCase()}`
              )}
              trackCategory={`${SupportGuidedTroubItemComponent}`}
              hoverable={true}
              appearance="outlined"
              tabindex="0"
              onClick={() => {
                isExecuteLauncher === true
                  ? launcherWinDiag()
                  : launchVA(resource?.serviceType)
              }}
              aria-label={`${resource.serviceTitle} ${resource.serviceDesc}`}
              content={
                <>
                  <IconContainer>
                    {contactMap[resource.serviceId]
                      ? contactMap[resource.serviceId](32, 'colorPrimary')
                      : contactMap.default()}
                  </IconContainer>
                  <ItemContent>
                    <ItemTitle
                      className="body"
                      id={formatAutoId(
                        `${component}-card-title-${resource.serviceId}`
                      )}
                    >
                      {resource.serviceTitle}
                    </ItemTitle>
                    <ItemDescription
                      className="caption-small"
                      id={formatAutoId(
                        `${component}-card-desc-${resource.serviceId}`
                      )}
                    >
                      {resource.serviceDesc}
                    </ItemDescription>
                  </ItemContent>
                </>
              }
            ></Box>
          </Col>
        ))}
    </FlexRow>
  )
}

export default ListView
