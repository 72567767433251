import React, { useContext, useCallback } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import useI18n from '@jarvis/react-hpx-support-shared/dist/hooks/useI18n'
import { Col, Column, FlexRow, Row } from './styles'
import { Section, Container, PageTitle, Header, Image, Text } from '../styles'
import ListView from './ListView'
import { va } from '../../assets/images'

const component = 'guided-troubleshooting'

const RootComponent = (props) => {
  const { t } = useI18n()
  const { formatAutoId } = useContext<any>(RootContext)
  const { device, Skelton } = props || {}
  console.log('******Diagnostics > Device***')

  const isPrinter = useCallback(() => {
    return device?.IsSubscriptionDevice && device?.ProductType === 1
  }, [device?.IsSubscriptionDevice, device?.ProductType])

  const getOurVirtualAssistantCanHelpYouDiagnose = useCallback(() => {
    return t('common.ourVirtualAssistantCanHelpYouDiagnose').split('/')
  }, [t])

  const getSplitStringForOurVirtualAssistantCanHelpYouDiagnose =
    getOurVirtualAssistantCanHelpYouDiagnose()

  return (
    <>
      {device ? (
        <>
          {device?.IsShowDeviceVA && (
            <Section>
              <Row>
                <Column>
                  <Header mt={15}>
                    {isPrinter() && (
                      <Image
                        src={va}
                        width={24}
                        height={24}
                        marginBottom={10}
                      />
                    )}
                    <PageTitle
                      className="subtitle-regular"
                      id={formatAutoId(`${component}-title`)}
                    >
                      {t('common.header')}
                    </PageTitle>
                    {isPrinter() && (
                      <>
                        <Text
                          mt={10}
                          className="caption"
                          tabIndex={-1}
                          id={formatAutoId(`${component}-printer-description`)}
                        >
                          {
                            getSplitStringForOurVirtualAssistantCanHelpYouDiagnose?.[0]
                          }
                        </Text>
                        <Text
                          mt={2}
                          className="caption"
                          tabIndex={-1}
                          id={formatAutoId(
                            `${component}-printer-selectYourIssueType`
                          )}
                        >
                          {
                            getSplitStringForOurVirtualAssistantCanHelpYouDiagnose?.[1]
                          }
                        </Text>
                      </>
                    )}
                  </Header>
                  <Container className="m-b-0">
                    <FlexRow L={1} M={1}>
                      {/* Support local and remote profile device to show the VA UI */}
                      {device?.IsShowDeviceVA && (
                        <>
                          {isPrinter() ? (
                            <>
                              <Col marginTop="0">
                                <ListView
                                  resources={[
                                    {
                                      serviceTitle: t(
                                        'common.hPAllInPlanSupport'
                                      ),
                                      serviceDesc: t(
                                        'common.reviewYourBillingSubscriptions'
                                      ),
                                      serviceType: 'HPAllInPlanSupportPeople',
                                      serviceId: 'HPAllInPlanSupportPeople'
                                    }
                                  ]}
                                  isExecuteLauncher={false}
                                  device={device}
                                ></ListView>
                              </Col>
                              <Col marginTop="0">
                                <ListView
                                  resources={[
                                    {
                                      serviceTitle: t(
                                        'common.printerAndInkSupport'
                                      ),
                                      serviceDesc: t(
                                        'common.solvePaperJamsScanIssuesAndMore'
                                      ),
                                      serviceType: 'PrinterAndInkSupport',
                                      serviceId: 'printerAndInkSupport'
                                    }
                                  ]}
                                  isExecuteLauncher={false}
                                  device={device}
                                ></ListView>
                              </Col>
                            </>
                          ) : (
                            <Col marginTop="0">
                              <ListView
                                resources={[
                                  {
                                    serviceTitle: t('common.VATitle'),
                                    serviceDesc: t('common.VADescription'),
                                    serviceType: 'Diagnostics',
                                    serviceId: 'va'
                                  }
                                ]}
                                isExecuteLauncher={false}
                                device={device}
                              ></ListView>
                            </Col>
                          )}
                        </>
                      )}
                    </FlexRow>
                  </Container>
                </Column>
              </Row>
            </Section>
          )}
        </>
      ) : (
        <Skelton />
      )}
    </>
  )
}

export default RootComponent
