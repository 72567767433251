export const Support = 'Support'
export const SupportDevice = 'Supportdevice'
export const BaseScreenPath = '/HpxWinClient'
export const BaseMobileScreenPath = '/HpxMobileClient'
export const DefaultScreenName = 'SupportDiagnostics'
export const DefaultMobileScreenName = 'SupportMobileDiagnostics'
export const DeviceScreenName = 'DeviceSupportDiagnostics'
export const DeviceMobileScreenName = 'DeviceMobileSupportDiagnostics'

export const TestIDPrefix = 'Supp'
export const ContainerComponent = 'DiagnosticsComponent'
export const MFEname = 'DeviceSupportDiagnostics'
export const DeviceComponent = 'DeviceComponent'
export const ProductIconComponent = 'ProductIconBtnComponent'
export const SupportDiagItemComponent = 'Diagonistics'
export const SupportGuidedTroubItemComponent = 'GuidedTroubleshooting'
