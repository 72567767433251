const rtl =
  (left, right) =>
  ({ theme }) =>
    theme.dir == 'rtl' ? right : left
export const left = rtl('left', 'right')
export const right = rtl('right', 'left')

const breakpoints = {
  L: '1008px',
  M: '640px',
  S: '500px'
}

const HPXTheme = {
  breakpoints,
  mediaQueries: {
    L: `@media only screen and (min-width: ${breakpoints.L})`,
    M: `@media only screen and (max-width: 1007px)`,
    S: `@media only screen and (max-width: ${breakpoints.M})`,
    XS: `@media only screen and (max-width: ${breakpoints.S})`
  }
}

export default HPXTheme
