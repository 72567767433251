import React, { useEffect, useState } from 'react'
import { ShellProps } from '../src/types/shell'
import { RootComponent } from '../src'
import { ThemeProvider } from '@veneer/theme'
import { DirectionProvider } from '@veneer/core/dist/esm/scripts/direction'
import RootProvider from '@jarvis/react-hpx-support-shared/dist/context/root/RootProvider'
import resources from '../src/assets/locale'
import { JarvisAnalyticsContextProvider } from '@jarvis/jweb-analytics'
import { WhenJWebReady } from '@jarvis/jweb-core'
import {
  BaseScreenPath,
  DeviceScreenName,
  DefaultScreenName,
  SupportDevice,
  Support,
  BaseMobileScreenPath,
  DefaultMobileScreenName,
  DeviceMobileScreenName
} from '../src/utils/constants'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

const JARVIS_ENVIRONMENT = {
  WEB: 'web',
  IOS: 'ios',
  ANDROID: 'android',
  WINDOWS: 'windows'
}

export default function Root(props) {
  console.log('******Diagnostics > MFE ***')
  const [initialScreenData, setInitialScreenData] = useState({
    screenPath: '',
    screenName: '',
    activity: ''
  })
  const { v1 } = window.Shell as ShellProps
  const namespace = '@jarvis/hpx-react-support'
  const theme = props?.themeProvider || {}

  const ensureWeb = async () => {
    const res = await WhenJWebReady
    return {
      platform: res.JWeb.platform,
      isNative: res.JWeb.isNative
    }
  }

  const initialData = async () => {
    const { platform, isNative } = await ensureWeb()
    console.log(`platform:${platform}`, `isNative:${isNative}`)
    if (
      platform == JARVIS_ENVIRONMENT.WEB ||
      platform == JARVIS_ENVIRONMENT.WINDOWS
    ) {
      setInitialScreenData({
        screenPath: `${BaseScreenPath}/${Support}/${SupportDevice}/`,
        screenName: DefaultScreenName,
        activity: `${DeviceScreenName}-v01`
      })
      console.log('web platform', platform, isNative)
    } else {
      setInitialScreenData({
        screenPath: `${BaseMobileScreenPath}/${Support}/${SupportDevice}/`,
        screenName: DefaultMobileScreenName,
        activity: `Diagnostics${platform}-v03`
        // activity: `${DeviceMobileScreenName}${platform?.toUpperCase()}-v01`
      })
      console.log('mobile platform', platform, isNative)
    }
  }

  useEffect(() => {
    initialData()
  }, [])

  return (
    <section id={namespace}>
      {initialScreenData.screenName !== '' &&
        initialScreenData.screenPath !== '' &&
        initialScreenData.activity !== '' && (
          <JarvisAnalyticsContextProvider
            initialScreenData={{
              screenName: initialScreenData.screenName,
              screenPath: initialScreenData.screenPath,
              activity: initialScreenData.activity
            }}
            // initialScreenData={{
            //   screenName: DefaultScreenName,
            //   screenPath: `${BaseScreenPath}/${Support}/${SupportDevice}/`,
            //   activity: `${DeviceScreenName}-v01`
            // }}
          >
            <DirectionProvider direction={theme.dir}>
              <ThemeProvider {...theme}>
                <RootProvider
                  localization={v1.localization}
                  resources={resources}
                  useModal={props?.useModal}
                  formatAutoId={props?.formatAutoId}
                  dir={theme.dir}
                  environment={props.environment}
                  navigation={props.navigation}
                  supportRoute={props.supportRoute}
                >
                  <RootComponent {...props} />
                </RootProvider>
              </ThemeProvider>
            </DirectionProvider>
          </JarvisAnalyticsContextProvider>
        )}
    </section>
  )
}
