/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import {
  ItemContent,
  ItemTitle,
  FlexRow,
  Col,
  Column,
  IconContainer
} from '../styles'
import { Box } from '../../styles'
import useLauncher from '../../../hooks/useLauncher'
import { genTestId } from '../../../utils'
import { SupportDiagItemComponent } from '../../../utils/constants'

const component = 'diagnostics'

const GridView = ({ resources, device }) => {
  const { launcherDaigTool, launcherWinDiag } = useLauncher(device)
  const { formatAutoId } = useContext<any>(RootContext)

  return (
    <FlexRow>
      {resources &&
        resources.map((resource) => (
          <Col size="1" flex="1" key={resource.name}>
            <Box
              role="button"
              className="cardStyle diag-pad"
              id={formatAutoId(`${component}-card-${resource.name}`)}
              hoverable={true}
              appearance="outlined"
              tabindex="0"
              trackId={genTestId('Diag', `${resource?.name?.toLowerCase()}`)}
              trackCategory={`${SupportDiagItemComponent}`}
              onClick={() => {
                resource?.command
                  ? launcherDaigTool(resource?.command)
                  : launcherWinDiag()
              }}
              aria-label={`${resource.title}`}
              content={
                <Column justifyContent="center">
                  <IconContainer>{resource?.iconType}</IconContainer>
                  <ItemContent>
                    <ItemTitle
                      className="body"
                      id={formatAutoId(
                        `${component}-card-title-${resource.name}`
                      )}
                    >
                      {resource.title}
                    </ItemTitle>
                  </ItemContent>
                </Column>
              }
            ></Box>
          </Col>
        ))}
    </FlexRow>
  )
}

export default GridView
