import styled, { css } from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import Tokens from '@veneer/tokens'
import HPXTheme, { left } from '../../styles/global'

const flexRow = css`
  display: flex;
  flex-direction: row;
`
const flexColumn = css`
  display: flex;
  flex-direction: column;
`
const flexAlignStart = css`
  align-items: flex-start;
  justify-content: flex-start;
`

export const Row = styled.div`
  ${flexRow};
`
export const Column = styled.div`
  ${flexColumn};
  flex: 1;
`
const defaultTitle = css`
  text-align: ${left};
  ${flexRow};
  font-style: normal;
  font-feature-settings: 'rclt' off;
`

export const ContentTitle = styled.h2`
  ${defaultTitle}
  flex: 1;
`

export const Body = styled.div`
  text-align: ${left};
  min-height: auto;
  ${flexColumn};
  ${flexAlignStart};
  flex: 1;
  margin: ${Tokens.space2} 0;
  margin-bottom: ${(props) =>
    props.marginBottom ? `${props.marginBottom}px` : Tokens.space2};
  margin-top: ${(props) =>
    props.marginTop ? `${props.marginTop}px` : Tokens.space2};
`

export const ItemContent = styled.div`
  text-align: ${left};
  ${flexColumn};
  flex: 1;
  margin-${left}: 14px;
`

export const ItemTitle = styled.span`
  margin: 0;
  color: ${({ theme }) => theme.colorForeground};
  &.label {
    font-size: ${Tokens.size4};
    line-height: ${Tokens.lineHeight3};
  }
`

export const ItemDescription = styled.span`
  display: inline-block;
  // margin-top: ${Tokens.space2};
  color: ${({ theme }) => theme.colorForegroundLight};
`

export const FlexRow = styled.div`
  display: grid;
  position: relative;
  flex-wrap: wrap;
  width: 100%;
  gap: ${Tokens.space4};
  ${HPXTheme.mediaQueries.L} {
    grid-template-columns: repeat(${(props) => props.L}, 1fr);
  }
  ${HPXTheme.mediaQueries.M} {
    grid-template-columns: repeat(${(props) => props.M}, 1fr);
  }
`

export const Col = styled.div`
  box-sizing: border-box;
  flex-basis: ${(props) => (props.size ? 100 / props.size : 100)}%;
  max-width: ${(props) => (props.size ? 100 / props.size : 100)}%;
  ${(props) =>
    props.flex &&
    css`
      display: flex;
      > div {
        flex: 1;
      }
    `}
`

export const IconContainer = styled.div`
  width: 40px;
  height: 52px;
  border-radius: 4px;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`
