import React, { useContext } from 'react'
import RootContext from '@jarvis/react-hpx-support-shared/dist/context/root/RootContext'
import { ThemeProvider } from 'styled-components'
import { useTheme } from '@veneer/theme'
import GuidedTroubleshooting from './GuidedTroubleshooting'
import Diagnostics from './Diagnostics'
import { LocalizationType, IEnvironment } from '../types/localization'
const VAGuideTroubleshooting = React.lazy(
  () => import('../mobile/GuidedTroubleShooting')
)
interface IContext {
  dir?: string
  localization?: LocalizationType
  environment?: IEnvironment
}

const RootComponent = (props) => {
  const theme = useTheme()
  const { dir, environment } = useContext<IContext>(RootContext)
  const { device } = props

  return (
    <ThemeProvider theme={{ ...theme, dir }}>
      {/** TODO: daignostics */}
      {device?.IsHostPC && !device?.IsSMode && device?.IsUserAdministrator && (
        <Diagnostics {...props}></Diagnostics>
      )}
      {environment?.isMobile ? (
        <VAGuideTroubleshooting {...props}></VAGuideTroubleshooting>
      ) : (
        <GuidedTroubleshooting {...props}></GuidedTroubleshooting>
      )}
    </ThemeProvider>
  )
}

export default RootComponent
