import styled, { css } from 'styled-components'
// You can use variables from veneer tokens and do something like the below
import Tokens from '@veneer/tokens'
import Card from '@veneer/core/dist/esm/scripts/card'
import { left, right } from '../styles/global'
import { withAnalytics } from '@veneer/analytics'
const TrackerCard = withAnalytics(Card)

const flexRow = css`
  display: flex;
  flex-direction: row;
`
const flexColumn = css`
  display: flex;
  flex-direction: column;
`
const flexAlignStart = css`
  align-items: flex-start;
  justify-content: flex-start;
`

export const Section = styled.div`
  min-height: auto;
  ${flexColumn};
  &.m-b-12 {
    margin-bottom: ${Tokens.space3};
  }
`

export const Container = styled.div`
  min-height: auto;
  ${flexColumn};
  margin: ${Tokens.space3} 0px;
  .icon-color {
    color: ${({ theme }) => theme.colorForeground};
  }
  &.m-b-0 {
    margin-bottom: 0px;
  }
`
export const Row = styled.div`
  ${flexRow};
`
export const Column = styled.div`
  ${flexColumn};
  flex: 1;
`

export const Header = styled.div`
  text-align: ${left};
  min-height: auto;
  ${flexColumn};
  ${flexAlignStart};
  ${(props) => props.mt && `margin-top: ${props.mt}px;`}
`

const defaultTitle = css`
  text-align: ${left};
  ${flexRow};
  font-style: normal;
  font-feature-settings: 'rclt' off;
`

export const ContentTitle = styled.h2`
  ${defaultTitle}
  flex: 1;
`

export const PageTitle = styled.h6`
  ${defaultTitle}
  color: ${({ theme }) => theme.colorForeground};
`

export const Box = styled(TrackerCard)`
  ${flexRow};
  &.cardStyle {
    background: ${({ theme }) => theme.colorBackgroundContainer};
    background-color: ${({ theme }) => theme.colorBackgroundContainer};
    border-color: ${({ theme }) => theme.colorBorder || Tokens.colorGray0};
    border-radius: ${Tokens.borderRadius3};
    cursor: pointer;
    &:active {
      border-width: 1px !important;
      margin: 0px !important;
    }
    &:hover {
      box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.1);
      ${({ theme }) =>
        theme.mode === 'light' &&
        css`
          border-color: ${({ theme }) => theme.colorBorder} !important;
        `}
    }
    @media screen and (-ms-high-contrast: active) {
      border-width: 1px;
    }
  }
  &.diag-pad {
    padding: ${Tokens.space2} ${Tokens.space5};
  }
  &:focus {
    box-shadow: 0px 4px 16px rgba(33, 33, 33, 0.1);
  }
  align-items: center;
  padding: ${Tokens.space5};
  flex: 0 0 calc(100%);
  position: relative;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 0)}px;
  cursor: pointer;
`

export const Image = styled.img`
  margin: auto 0;
  margin-${right}: ${Tokens.space6};
  margin-top: 0px;
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}
  ${(props) => {
    props.width &&
      css`
        width: ${props.width};
      `
  }}

  ${(props) => {
    props.height &&
      css`
        height: ${props.height};
      `
  }}
`

export const Text = styled.span`
  color: ${({ theme }) => theme?.colorForeground || Tokens.colorGray10};
  ${(props) => props?.mt && `margin-top: ${props?.mt}px;`}
`
